<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-22 16:54:04
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-25 15:08:38
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/SettleManager/ADXSettlementNav/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adx-settle-wrapper">
    <template v-for="(item) in aggregationList" >
      <div class="item-box" :key="item.title">
        <div class="item-top">
          <component :is="item.iconUrl" class="svg-icon" />
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="desc">
          <span>{{ item.desc }}</span>
        </div>
      </div>
      <div class="item-arrow" v-if="item.hasLine" :key="item.title + 'arrow'">
        <LineSvg/>
      </div>
    </template>
  </div>
  <!-- 结算说明 -->
  <!-- 弹窗 -->
</template>

<script>
import Svg1 from './svgs/navSvg1.svg?inline'
import Svg2 from './svgs/navSvg2.svg?inline'
import Svg3 from './svgs/navSvg3.svg?inline'
import Svg4 from './svgs/navSvg4.svg?inline'
import LineSvg from './svgs/Line.svg?inline'
export default {
  name: 'ADXSettleNav',
  components: { Svg1, Svg2, Svg3, Svg4, LineSvg },
  data () {
    return {
      aggregationList: [
        {
          title: '申请结算',
          desc: ' 申请结算并下载核对结算单',
          iconUrl: 'Svg1',
          hasLine: true
        },
        {
          title: '正确开具发票',
          desc: '选择线下邮寄/线上提交电子发票',
          hasLine: true,
          iconUrl: 'Svg2'
        },

        {
          title: '发票审核',
          desc: '财务审核发票金额与结算金额一致',
          hasLine: true,
          iconUrl: 'Svg3'
        },
        {
          title: '支付结算金额',
          desc: '发票审核通过后，财务支付结算金额',
          hasLine: false,
          iconUrl: 'Svg4'
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.adx-settle-wrapper{
  padding: @smallMargin;
  border-radius: @mediumRadius;
  background-color: #fff;
  margin-top: @mediumMargin;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
  height: 102px;
  .item-box{
    flex-basis: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    .item-top{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: @assisColor;
      width: 100%;
      margin-bottom: 10px;
      .svg-icon{
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }
      .title{
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
      }
    }
    .desc{
      font-size: 14px;
      color: #344563;
    }
  }
  .item-arrow{
    flex: 1 auto;
    display: flex;
    align-items: center;
  }
}
</style>
